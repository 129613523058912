import * as React from "react"

import HeaderNav from "../HeaderNav"
import Logo from "../Logo"

const Header = () => {
  return (
    <div className="header">
      <div className="header--wrap wrapper">
        <Logo />

        <HeaderNav />
      </div>
    </div>
  )
}

export default Header
