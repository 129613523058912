import * as React from 'react'
import { Link } from 'gatsby'

const Logo = () => {
  return (
    <Link to="/" className="logo">
      АК
    </Link>
  )
}

export default Logo
