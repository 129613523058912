import React from "react"

import "../../css/css/main.sass"

import Header from "../Header"
import Content from "../Content"

class Layout extends React.Component<any> {
  render() {
    const { children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    return (
      <>
        <Header />
        <Content>{children}</Content>
      </>
    )
  }
}

export default Layout
