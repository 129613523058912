import * as React from 'react'
import { Link } from 'gatsby'

const HeaderNav = () => {
  return (
    <nav className="header_nav">
      <Link className="header_nav--item" to="/" activeClassName="-active">
        Articles
      </Link>
      <Link className="header_nav--item" to="/about/" activeClassName="-active">
        About
      </Link>
    </nav>
  )
}

export default HeaderNav
