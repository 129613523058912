import React from 'react'
import userpic from './images/userpic.png'
import Layout from '../../components/Layout'

export default class About extends React.Component {
  render() {
    return (
      <Layout>
        <section className="about">
          <header className="about--header">
            <figure>
              <img src={userpic} height="100" width="100" alt="" />
            </figure>
            <h1>Alexei Crecotun</h1>
            <h2>Senior Frontend Engineer</h2>
          </header>
          <div className="about--content">
            <dl>
              <dt>Links</dt>
              <dd>
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/in/crecotun">linkedin</a>
                  </li>
                  <li>
                    <a href="https://github.com/crecotun">github</a>
                  </li>
                  <li>
                    <a href="https://twitter.com/crecotun">twitter</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </section>
      </Layout>
    )
  }
}
